/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// Core
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

// Instruments
import Layout from '../../layouts/layout';
import VerticalLines from '../../components/vertical-lines/ vertical-lines';
import Container from '../../layouts/container/container';
import { setRtl, transformPostDate, unsetRtl } from '../../helpers/helpers';
import styles from '../blog-post.module.scss';
import PostNavigation from '../../components/post-navigation/post-navigation';
import SubscriptionSection from '../../pages-sections/home-sections/subscription-section/subscription-section';

export default function Template({ data, location }) {
  const post = data.allWordpressPost.edges[0].node;
  const { day, month, year } = transformPostDate(post.date);
  const [sectionLoaded, setSectionLoaded] = useState(false);
  const dateStr = `${month} ${year}`;

  useEffect(() => {
    setSectionLoaded(true);
  }, []);
  useEffect(() => {
    setRtl(location.pathname);
    return () => {
      unsetRtl();
    };
  }, []);

  return (
    <>
      <Layout
        location={location}
        darkBurger
        footerData={
          {
            footerLink1: post.acf.footer_link_1,
            footerLink2: post.acf.footer_link_2,
            footerLink3: post.acf.footer_link_3,
            footerLink4: post.acf.footer_link_4,
            footerLink5: post.acf.footer_link_5,
            footerLink6: post.acf.footer_link_6,
            footerEmailLabel: post.acf.footer_email_label,
            footerEmail: post.acf.footer_email,
            footerPhoneLabel: post.acf.footer_phone_label,
            footerPhone: post.acf.footer_phone,
            copyright: post.copyright,
          }
        }
      >
        <div className={`${styles.blogPostContainer} ${sectionLoaded ? styles.startAnimation : ''}`}>
          <VerticalLines />
          <Container>
            <Helmet title={`Your Blog Name - ${post.title}`} />
            <div className={styles.blogPost}>
              <div className={`${styles.postDate} ${styles.animatedTextContainer}`}>
                <div className={`animatedSliderText ${styles.day} ${styles.animatedText}`}>{day}</div>
                <div className={`animatedSliderText ${styles.date} ${styles.animatedText}`}>
                  {dateStr}
                </div>
              </div>
              <div className={`${styles.blogPostContent} ${styles.animatedTextContainer}`}>
                <div className={styles.blogPostContentBox}>
                  <h1 className={styles.blogPostTitle}>{post.title}</h1>
                  <div
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  />
                </div>
                <PostNavigation activeItem={post.slug} posts={data.allWordpressPost.edges} />
              </div>
            </div>
          </Container>
        </div>
        <SubscriptionSection
          data={
          {
            title: post.acf.subscription_title,
            subTitle: post.acf.subscription_sub_title,
            placeholder: post.acf.subscription_placeholder,
            button: post.acf.subscription_button,
            empty: post.acf.email_empty,
            exist: post.acf.email_exist,
            success: post.acf.success,
          }
        }
        />
      </Layout>
    </>
  );
}
export const pageQuery = graphql`
    query($id: String!) {
        wordpressPost(id: { eq: $id }) {
          title
          id
          template
          content
          slug
          date(locale: "")
          acf {
            subscription_title
            subscription_sub_title
            subscription_placeholder
            subscription_button
            email_empty
            email_exist
            success

            footer_email
            footer_email_label
            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_phone_label
            footer_phone
            copyright

            post_text
            post_title
          }
        }
        allWordpressPost(filter: {categories: {eq: 14}}) {
          edges {
            node {
              title
              id
              template
              content
              slug
              date(locale: "")
              acf {
                post_text
                post_title
                subscription_title
                subscription_sub_title
                subscription_placeholder
                subscription_button
                email_empty
                email_exist
                success
    
                footer_email
                footer_email_label
                footer_link_1
                footer_link_2
                footer_link_3
                footer_link_4
                footer_link_5
                footer_link_6
                footer_phone_label
                footer_phone
                copyright
              }
            }
          }
        }
    }
`;
